<template>
  <div class="container full organisation">
    <div class="white"></div>
    <div class="container">
      <div class="columns">
        <div class="column is-center">
          <section>
            <p v-html='$t("logos.text")'></p>
            <div class="columns logos">
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.hetcak.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="CAK" v-lazy="cak"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.bibliotheek.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="de Bibliotheek" v-lazy="bibliotheek"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.belastingdienst.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="Belastingdienst" v-lazy="belastingdienst"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.lbio.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img
                    alt="LBIO Landelijk Bureau Inning Onderhoudsbijdragen"
                    v-lazy="lbio"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.cjib.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img
                    alt="Centraal Justitieel Incassobureau Ministerie van Justitie en Veiligheid"
                    v-lazy="cjib"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.svb.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="SVB" v-lazy="svb"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.rvr.org/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="Raad voor Rechtsbijstand" v-lazy="rvr"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://duo.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img
                    alt="Dienst Uitvoering Onderwijs Minister van Onderwijs, Cultuur en Wetenschap"
                    v-lazy="duo"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.rdw.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="RDW" v-lazy="rdw"
                /></a>
              </div>
              <div class="column is-6-mobile is-2-desktop">
                <a
                  class="inner focus"
                  href="https://www.huurcommissie.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="Huurcommissie" v-lazy="hc"
                /></a>
              </div>
              <div class="column is-6-mobile is-1-desktop">
                <a
                  class="inner focus"
                  href="https://www.ciz.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="CIZ" v-lazy="ciz"
                /></a>
              </div>
              <div class="column is-6-mobile is-1-desktop">
                <a
                  class="inner focus"
                  href="https://www.cbr.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="CBR" v-lazy="cbr"
                /></a>
              </div>
              <div class="column is-6-mobile is-1-desktop">
                <a
                  class="inner focus"
                  href="https://www.uwv.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img alt="UWV" v-lazy="uwv"
                /></a>
              </div>
              <div class="column is-6-mobile is-3-desktop">
                <a
                  class="inner focus"
                  href="https://www.toeslagen.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img
                    alt="Toeslagen Ministerie van Financiën"
                    v-lazy="toeslagen"
                /></a>
              </div>
              <div class="column is-6-mobile is-3-desktop">
                <a
                  class="inner focus"
                  href="https://www.logius.nl/"
                  target="_blank"
                  rel="noopener"
                  ><img
                    alt="Logius Ministerie van Binnenlandse Zaken en Koninkrijkrelaties"
                    v-lazy="logius"
                /></a>
              </div>
            </div>
            <a
              class="button focus"
              :title='$t("button")'
              href="https://www.hetinformatiepunt.nl/is/idos"
              target="_blank"
              rel="noopener"
              @click="track"
            >
              <span>{{ $t("button") }}</span>
            </a>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Organisation",
  data() {
    return {
      cak: require("../assets/logos/cak.svg"),
      bibliotheek: require("../assets/logos/logo-bibliotheek.svg"),
      belastingdienst: require("../assets/logos/Logo_Belastingdienst.svg"),
      lbio: require("../assets/logos/logo-lbio.svg"),
      cjib: require("../assets/logos/logo-cjib.svg"),
      svb: require("../assets/logos/logo-svb.svg"),
      rvr: require("../assets/logos/rvr.svg"),
      duo: require("../assets/logos/logo-duo.svg"),
      rdw: require("../assets/logos/logo-rdw-particulier.svg"),
      ciz: require("../assets/logos/ciz.svg"),
      cbr: require("../assets/logos/cbr.svg"),
      uwv: require("../assets/logos/logo-uwv.svg"),
      logius: require("../assets/logos/Logius-logo.svg"),
      toeslagen: require("../assets/logos/bld_logo_tsl.svg"),
      hc: require("../assets/logos/logo-huurcommissie.svg")
    };
  },
  methods: {
    track() {
      // window._paq.push([
      //   "trackEvent",
      //   "Outbound links",
      //   "click",
      //   "Vind een informatiepunt - footer"
      // ]);
    }
  }
};
</script>
