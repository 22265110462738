import "classlist";
import "intersection-observer";
import Vue from "vue";
import App from "./App.vue";
import VueLazyload from "vue-lazyload";
import inViewportDirective from "vue-in-viewport-directive";
import LazyYoutubeVideo from "vue-lazy-youtube-video";
import "vue-lazy-youtube-video/dist/style.css";
//import VueMatomo from "vue-matomo";
import i18n from "./i18n";

Vue.use(VueLazyload);

inViewportDirective.defaults.margin = "-10% 0%";
Vue.directive("in-viewport", inViewportDirective);

Vue.component("LazyYoutubeVideo", LazyYoutubeVideo);

Vue.config.productionTip = false;

// Vue.use(VueMatomo, {
//   host: "https://matomo.informatiepuntdigitaleoverheid.acc.estdigital.nl",
//   siteId: 1,
//   trackerFileName: "matomo",
//   enableLinkTracking: true,
//   requireConsent: false,
//   trackInitialView: true,
//   disableCookies: false,
//   enableHeartBeatTimer: false,
//   heartBeatTimerInterval: 15,
//   debug: false,
//   userId: undefined,
//   cookieDomain: undefined,
//   domains: undefined,
//   preInitActions: []
// });

// window._paq.push(["trackPageView"]);

new Vue({
  i18n,
  render: h => h(App)
}).$mount("#app");
