<template>
  <div class="container content">
    <main>
      <div class="columns">
        <div class="column image-top">
          <div class="image image-container">
            <div class="background">
              <div class="fade" v-bind:class="{ active: showRandom02 }"></div>
              <div class="image" v-lazy:background-image="imageRandom02"></div>
            </div>
          </div>
        </div>
        <div class="column green bold text text-top">
          <section v-html='$t("textmiddle.content")'></section>
        </div>
      </div>
      <div class="columns">
        <div class="column white bold text text-bottom">
          <section class="extra">
            <div class="info">
              <img alt="Informatiepunt" v-lazy="info" v-in-viewport />
            </div>
            <p class="center" v-html='$t("textbottom.content")'></p>
          </section>
        </div>
        <div class="column image-bottom">
          <div class="image image-container">
            <div class="background">
              <div class="fade" v-bind:class="{ active: showRandom03 }"></div>
              <div class="image" v-lazy:background-image="imageRandom03"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Content",
  data() {
    return {
      info: require("../assets/info.svg"),
      imageRandom02: "",
      random02: true,
      showRandom02: true,
      imageRandom03: "",
      random03: true,
      showRandom03: true,
      image03: "",
      image04: "",
      image05: "",
      image06: ""
    };
  },
  created() {
    function check_webp_feature(feature, callback) {
      let kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha:
          "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation:
          "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
      };
      let img = new Image();
      img.onload = function() {
        let result = img.width > 0 && img.height > 0;
        callback(feature, result);
      };
      img.onerror = function() {
        callback(feature, false);
      };
      img.src = "data:image/webp;base64," + kTestImages[feature];
    }
    check_webp_feature("lossy", (feature, isSupported) => {
      if (isSupported) {
        this.image03 = require("../assets/image03.webp");
        this.image04 = require("../assets/image04.webp");
        this.image05 = require("../assets/image05.webp");
        this.image06 = require("../assets/image06.webp");

        this.imageRandom02 = this.image03;
        this.imageRandom03 = this.image05;

        this.randomImage02();
        this.randomImage03();
      } else {
        this.image03 = require("../assets/image03.jpg");
        this.image04 = require("../assets/image04.jpg");
        this.image05 = require("../assets/image05.jpg");
        this.image06 = require("../assets/image06.jpg");

        this.imageRandom02 = this.image03;
        this.imageRandom03 = this.image05;

        this.randomImage02();
        this.randomImage03();
      }
    });
  },
  methods: {
    randomImage02() {
      let randomNr = Math.floor(Math.random() * 2 + 1);
      if (randomNr === 1) {
        this.imageRandom02 = this.image03;
      } else if (randomNr === 2) {
        this.imageRandom02 = this.image04;
      }
    },
    randomImage03() {
      let randomNr = Math.floor(Math.random() * 2 + 1);
      if (randomNr === 1) {
        this.imageRandom03 = this.image05;
      } else if (randomNr === 2) {
        this.imageRandom03 = this.image06;
      }
    }
  }
};
</script>
