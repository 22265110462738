/**
 * Maak een Local Storage item aan met een value en key.
 *
 * @param {string} keyName Naam van de key.
 * @param {string} keyValue Inhoud van de item.
 */
export const setParam = (keyName, keyValue) => {
  const url = new URL(window.location.href);
  url.searchParams.set(keyName, keyValue);
  window.history.replaceState(null, null, url);
};

/**
 * Krijg de Local Storage item met een key.
 *
 * @param {string} keyName Naam van de key.
 */
export const getParam = keyName => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(keyName);
};
