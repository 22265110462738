/**
 * Maak een Local Storage item aan met een value en key.
 *
 * @param {string} keyName Naam van de key.
 * @param {string} keyValue Inhoud van de item.
 */
export const localSetItem = (keyName, keyValue) => {
  localStorage.setItem(keyName, keyValue);
};

/**
 * Krijg de Local Storage item met een key.
 *
 * @param {string} keyName Naam van de key.
 */
export const localGetItem = keyName => {
  if (localStorage.getItem(keyName)) {
    return localStorage.getItem(keyName);
  } else {
    return false;
  }
};

/**
 * Verwijder de Local Storage item met de key.
 *
 * @param {string} keyName Naam van de key.
 */
export const localRemoveItem = keyName => {
  if (!localStorage.getItem(keyName)) return;
  localStorage.removeItem(keyName);
};
