<template>
  <transition name="show">
    <div v-show="openModal" class="modal" role="dialog">
      <div class="modal__outer">
        <div class="modal__outside" @click="closeModal"></div>
        <div class="modal__inner">
          <button
            :aria-label='$t("accessibility.close")'
            class="modal__inner--close"
            ref="first"
            tabindex="0"
            @click="closeModal"
          ></button>
          <p v-html='$t("accessibility.content")'></p>
          <a
            title="Ga naar de toegankelijkheidsverklaring"
            href="https://www.toegankelijkheidsverklaring.nl/register/4648"
            target="_blank"
            rel="noopener"
            ><img
              src="https://www.toegankelijkheidsverklaring.nl/files/verklaring/label/7c8f095f0e28d18a880f84333622e23b.4648.svg"
              alt="Status toegankelijkheidslabel van Informatiepunt Digitale Overheid. Volg de link voor de volledige toegankelijkheidsverklaring."
          /></a>
          <div class="btns">
            <a
              title="Ga naar de toegankelijkheidsverklaring"
              href="https://www.toegankelijkheidsverklaring.nl/register/4648"
              target="_blank"
              rel="noopener"
              class="button external"
              ><span>{{ $t("accessibility.button") }}</span></a
            >
            <button class="button" type="button" @click="closeModal">
              <span>{{ $t("accessibility.close") }}</span>
            </button>
          </div>
        </div>
      </div>
      <span tabindex="0" @focus="focusFirstElement()"></span>
    </div>
  </transition>
</template>

<script>
import emitter from "@/services/emitter";
import { setParam } from "@/util/param/params"

export default {
  name: "Accessibility",
  data() {
    return {
      openModal: false,
      url: "",
      href: "",
      slug: "toegankelijkheidsverklaring"
    };
  },
  watch: {
    openModal(bool) {
      const body = document.body;
      const html = document.documentElement;
      const focusAbles = document.querySelectorAll(".focus");

      if (bool) {
        body.classList.add("is-hidden");
        html.classList.add("is-hidden");

        focusAbles.forEach(element => {
          element.classList.add("disabled");
          element.setAttribute("tabindex", -1);
        });

        this.addParam();
      } else {
        body.classList.remove("is-hidden");
        html.classList.remove("is-hidden");

        focusAbles.forEach(element => {
          element.classList.remove("disabled");
          element.setAttribute("tabindex", 0);
        });

        this.removeParam();
      }
    }
  },
  created() {
    document.addEventListener("keydown", (event) => {
      if (event.isComposing || event.keyCode === 27) {
        this.openModal = false;
      }
    });
    emitter.on("openAccessibilityModal", isOpen => {
      this.openModal = isOpen;
    });
    this.url = window.location.origin + "/";
    this.href = window.location.href;

    if (this.href.match(this.slug)) {
      this.openModal = true;
    }
  },
  methods: {
    closeModal() {
      this.openModal = false;
    },
    addQueryStringParameter(url, value) {
      if (value === null || value === undefined) {
        return url;
      }
      if (url.match(value)) {
        return url;
      } else {
        return url + value;
      }
    },
    removeQueryStringParameter(url, value) {
      if (value === null || value === undefined) {
        return url;
      }
      if (url.match(value)) {
        let re = new RegExp(`\\b${value}\\b`, "gi");
        let replace = url.replace(re, "");
        return replace;
      } else {
        return url;
      }
    },
    addParam() {
      this.url = this.addQueryStringParameter(this.url, this.slug);
      window.history.replaceState(null, null, this.url);
      setParam("lang", this.$root.$i18n.locale);
    },
    removeParam() {
      this.url = this.removeQueryStringParameter(this.url, this.slug);
      window.history.replaceState(null, null, this.url);
      setParam("lang", this.$root.$i18n.locale);
    },
    focusFirstElement() {
      this.$refs.first.focus();
    }
  }
};
</script>
