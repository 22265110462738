import Vue from "vue";
import VueI18n from "vue-i18n";
import getBrowserLocale from "@/util/i18n/get-browser-locale";
import { supportedLocalesInclude } from "./util/i18n/supported-locales";
import { localSetItem, localGetItem } from "@/util/localstorage/local-storage";
import { setParam, getParam } from "@/util/param/params";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",

    true,

    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];

      messages[locale] = locales(key);
    }
  });

  return messages;
}

function getStartingLocale() {
  if (getParam("lang")) return getParam("lang");
  if (localGetItem("lang")) return localGetItem("lang");

  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en";
  }
}

document.documentElement.setAttribute("lang", getStartingLocale());
document.title = loadLocaleMessages()[getStartingLocale()].meta.title;
document.querySelector(
  'meta[name="description"]'
).content = loadLocaleMessages()[getStartingLocale()].meta.description;
document
  .querySelector("meta[property='og:title']")
  .setAttribute(
    "content",
    loadLocaleMessages()[getStartingLocale()].meta.title
  );
document
  .querySelector("meta[property='og:description']")
  .setAttribute(
    "content",
    loadLocaleMessages()[getStartingLocale()].meta.description
  );
document
  .querySelector("meta[name='twitter:title']")
  .setAttribute(
    "content",
    loadLocaleMessages()[getStartingLocale()].meta.title
  );
document
  .querySelector("meta[name='twitter:description']")
  .setAttribute(
    "content",
    loadLocaleMessages()[getStartingLocale()].meta.description
  );

setParam("lang", getStartingLocale());
localSetItem("lang", getStartingLocale());

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});
