<template>
  <div class="locale-switcher">
    <button class="button" v-if="$i18n.locale === 'en'" @click="$i18n.locale = 'nl'"><span>{{ $t('switch') }}</span></button>
    <button class="button" v-if="$i18n.locale === 'nl'" @click="$i18n.locale = 'en'"><span>{{ $t('switch') }}</span></button>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"
import { localSetItem } from "@/util/localstorage/local-storage"
import { setParam } from "@/util/param/params"

export default {
  data: () => ({ locales: getSupportedLocales() }),
  watch: {
    '$i18n.locale': function(newVal) {
      localSetItem('lang', newVal);
      setParam('lang', newVal);
      document.documentElement.setAttribute("lang", newVal);
      document.title = this.$root.$t("meta.title")
      document.querySelector(
        'meta[name="description"]'
      ).content = this.$root.$t("meta.description")
      document
        .querySelector("meta[property='og:title']")
        .setAttribute(
          "content",
          this.$root.$t("meta.title")
        );
      document
        .querySelector("meta[property='og:description']")
        .setAttribute(
          "content",
          this.$root.$t("meta.description")
        );
      document
        .querySelector("meta[name='twitter:title']")
        .setAttribute(
          "content",
          this.$root.$t("meta.title")
        );
      document
        .querySelector("meta[name='twitter:description']")
        .setAttribute(
          "content",
          this.$root.$t("meta.description")
        );
    }
  }
}
</script>
