<template>
  <div id="app" class="wrapper">
    <Home />
    <footer class="footer">
      <div class="container">
        <div class="columns">
          <div class="column">
            <section>
              <p>&copy; {{ year }} Informatiepunt Digitale Overheid</p>
            </section>
          </div>
          <div class="column">
            <section>
              <button class="openmodal btn focus" @click="openAccessibility">
                {{ $t("accessibility.open") }}
              </button>
              <button class="openmodal btn focus" @click="openVulnerability">
                {{ $t("vulnerability.open") }}
              </button>
            </section>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Home from "@/layout/Home.vue";
import emitter from "@/services/emitter";

export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  components: {
    Home
  },
  methods: {
    openAccessibility() {
      emitter.emit("openAccessibilityModal", true);
      // window._paq.push([
      //   "trackEvent",
      //   "Navigatie",
      //   "open toegankelijkheid",
      //   "Toegankelijkheidsverklaring"
      // ]);
    },
    openCookie() {
      emitter.emit("openCookieModal", true);
      // window._paq.push([
      //   "trackEvent",
      //   "Navigatie",
      //   "open cookies",
      //   "Toegankelijkheidsverklaring"
      // ]);
    },
    openVulnerability() {
      emitter.emit("openVulnerabilityModal", true);
      // window._paq.push([
      //   "trackEvent",
      //   "Navigatie",
      //   "open cookies",
      //   "Toegankelijkheidsverklaring"
      // ]);
    }
  }
};
</script>

<style lang="scss">
@import "./scss/variables.scss";
</style>
