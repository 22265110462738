<template>
  <div class="home">
    <header>
      <div class="container full">
        <div class="columns">
          <div class="column logo-container is-5-ds-sm">
            <span class="logo">
              <img
                class="image"
                src="../assets/informatiepunt-digitale-overheid.svg"
                alt="Informatiepunt Digitale Overheid"
              />
            </span>
          </div>
          <div class="column is-7-ds-sm">
            <div class="header image-container">
              <div class="background">
                <div class="fade" v-bind:class="{ active: showRandom01 }"></div>
                <div
                  class="image"
                  v-lazy:background-image="imageRandom01"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column is-7-ds-sm is-6-ds-bg">
            <section>
              <LocaleSwitcher />
              <h1>{{ $t("header.title") }}</h1>
              <p>{{ $t("header.content") }}</p>
              <a
                class="button focus"
                :title='$t("button")'
                href="https://www.hetinformatiepunt.nl/is/idos"
                target="_blank"
                rel="noopener"
                @click="track"
              >
                <span>{{ $t("button") }}</span
                >
              </a>
            </section>
          </div>
        </div>
      </div>
    </header>
    <Content />
    <!-- <Video /> -->
    <Organisation />
    <Accessibility />
    <Vulnerability />
    <Cookies />
  </div>
</template>

<script>
import Content from "@/components/Content.vue";
// import Video from "@/components/Video.vue";
import Organisation from "@/components/Organisation.vue";
import Accessibility from "@/components/Accessibility.vue";
import Vulnerability from "@/components/Vulnerability.vue";
import Cookies from "@/components/Cookies.vue";
import LocaleSwitcher from "@/layout/LocaleSwitcher.vue";

export default {
  name: "Home",
  data() {
    return {
      imageRandom01: "",
      random01: true,
      showRandom01: true,
      image01: "",
      image02: ""
    };
  },
  components: {
    Content,
    // Video,
    Organisation,
    Accessibility,
    Vulnerability,
    Cookies,
    LocaleSwitcher
  },
  created() {
    function check_webp_feature(feature, callback) {
      let kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha:
          "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation:
          "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
      };
      let img = new Image();
      img.onload = function() {
        let result = img.width > 0 && img.height > 0;
        callback(feature, result);
      };
      img.onerror = function() {
        callback(feature, false);
      };
      img.src = "data:image/webp;base64," + kTestImages[feature];
    }
    check_webp_feature("lossy", (feature, isSupported) => {
      if (isSupported) {
        this.image01 = require("../assets/image01.webp");
        this.image02 = require("../assets/image02.webp");

        this.imageRandom01 = this.image01;

        this.randomImage01();
      } else {
        this.image01 = require("../assets/image01.jpg");
        this.image02 = require("../assets/image02.jpg");

        this.imageRandom01 = this.image01;

        this.randomImage01();
      }
    });
  },
  methods: {
    randomImage01() {
      let randomNr = Math.floor(Math.random() * 2 + 1);
      if (randomNr === 1) {
        this.imageRandom01 = this.image01;
      } else if (randomNr === 2) {
        this.imageRandom01 = this.image02;
      }
    },
    track() {
      // window._paq.push([
      //   "trackEvent",
      //   "Outbound links",
      //   "click",
      //   "Vind een informatiepunt - header"
      // ]);
    }
  }
};
</script>
